<template>
    <a-drawer
        title="Список приглашений"
        :visible="visible"
        class="in_list_drawer"
        @close="visible = false"
        destroyOnClose
        :zIndex="zIndex"
        :width="drawerWidth"
        placement="right">
        <template v-if="orgId">
            <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="Пользователи">
                    <Users :org="org" :closeDrawer="closeDrawer" />
                </a-tab-pane>
                <a-tab-pane key="2" tab="Организации">
                    <Organizations :org="org" :closeDrawer="closeDrawer" />
                </a-tab-pane>
            </a-tabs>
        </template>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import Users from './Users.vue'
import Organizations from './Organizations.vue'
export default {
    name: "InvitationListDrawer",
    components: {
        Users,
        Organizations
    },
    props: {
        zIndex: {
            type: Number,
            default: 1050
        },
        org: {
            type: [Object],
            required: true
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 600)
                return 600
            else {
                return '100%'
            }
        }
    },
    created() {
        eventBus.$on(`open_inv_list_${this.org.id}`, id => {
            this.orgId = id
            this.visible = true
        })
    },
    data() {
        return {
            orgId: null,
            visible: false
        }
    },
    methods: {
        closeDrawer() {
            this.visible = false
        }
    },
    beforeDestroy() {
        eventBus.$off(`open_inv_list_${this.org.id}`)
    }
}
</script>

<style lang="scss" scoped>
.in_list_drawer{
    &::v-deep{
        .ant-tabs-tab{
            margin-right: 0px;
        }
        .ant-tabs-bar{
            margin: 0px;
        }
        .ant-drawer-content,
        .ant-drawer-wrapper-body{
            overflow: hidden;
        }
        .ant-drawer-body{
            overflow-y: auto;
            height: calc(100% - 40px);
            padding: 0px;
        }
    }
}
</style>