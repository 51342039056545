<template>
    <a-drawer
        :title="drawerTitle"
        :visible="visible"
        class="oc_drawer"
        @close="visible = false"
        destroyOnClose
        :zIndex="zIndex"
        :width="drawerWidth"
        :afterVisibleChange="afterVisibleChange"
        placement="right">
        <div class="drawer_body">
            <a-form-model
                ref="orgForm"
                :model="form"
                :rules="rules">
                <template v-if="!isDepartment">
                    <a-form-model-item
                        ref="logo"
                        label=""
                        prop="logo">
                        <div class="relative flex items-center">
                            <div :key="logoUrl || visible">
                                <a-avatar
                                    :size="60"
                                    :src="logoUrl"
                                    icon="picture" />
                            </div>
                            <div class="ml-2">
                                <label for="or_logo_upload" class="ant-btn ant-btn-dashed flex items-center justify-center">
                                    <i class="fi fi-rr-cloud-upload-alt mr-2"></i> {{ logoUrl ? 'Заменить логотип' : 'Загрузить логотип' }}
                                </label>
                                <input
                                    type="file"
                                    id="or_logo_upload"
                                    style="display:none;"
                                    ref="orLogoUpload"
                                    v-on:change="handleFileChange"
                                    accept=".jpg, .jpeg, .png, .gif" />
                            </div>
                        </div>
                    </a-form-model-item>
                </template>
                <a-form-model-item
                    ref="full_name"
                    :label="formFullNameLabel"
                    prop="full_name">
                    <a-input
                        :max-length="255"
                        v-model="form.full_name"
                        @pressEnter="formSubmit()"
                        size="large" />
                </a-form-model-item>
                <a-form-model-item
                    ref="name"
                    :label="formShortNameLabel"
                    prop="name">
                    <a-input
                        :max-length="255"
                        v-model="form.name"
                        @pressEnter="formSubmit()"
                        size="large" />
                </a-form-model-item>
                <template v-if="!isDepartment">
                    <a-form-model-item
                        ref="inn"
                        label="БИН/ИИН"
                        prop="inn">
                        <a-input
                            :max-length="12"
                            v-model="form.inn"
                            @pressEnter="formSubmit()"
                            type="number"
                            max="12"
                            size="large" />
                    </a-form-model-item>
                </template>
                <template v-if="isDepartment || isStructure || edit">
                    <a-form-model-item
                        ref="director"
                        label="Руководитель"
                        prop="director"
                        labelAlign="left">
                        <DrawerSelectUser
                            v-model="form.director"
                            inputSize="large"
                            class="w-full"
                            :isDepartment="isDepartment"
                            :parentId="organizationParent"
                            :organizationId="organizationParent"
                            isDirectorSelect
                            title="Выбрать сотрудника" />
                    </a-form-model-item>
                </template>
                <template v-if="!isDepartment">
                    <a-form-model-item
                        ref="contractor_parent"
                        label="Родительская организация"
                        prop="inn">
                        <DrawerSelectOrganization
                            v-model="form.contractor_parent"
                            inputSize="large"
                            class="w-full"
                            :organizationId="organization && organization.id || null"
                            title="Выбрать организацию" />
                    </a-form-model-item>
                </template>
                <a-form-model-item
                    ref="email"
                    label="E-mail"
                    prop="email">
                    <a-input
                        :max-length="255"
                        v-model="form.email"
                        @pressEnter="formSubmit()"
                        type="email"
                        size="large" />
                </a-form-model-item>
                <a-form-model-item
                    ref="phone"
                    label="Телефон"
                    prop="phone">
                    <a-input
                        :max-length="255"
                        v-model="form.phone"
                        @pressEnter="formSubmit()"
                        type="tel"
                        size="large" />
                </a-form-model-item>
                <template v-if="!isDepartment">
                    <a-form-model-item
                        ref="doc_prefix"
                        label="Префикс"
                        prop="doc_prefix">
                        <a-input
                            :max-length="4"
                            v-model="form.doc_prefix"
                            @pressEnter="formSubmit()"
                            size="large" />
                    </a-form-model-item>
                    <a-alert
                        class="mb-4"
                        message="Префикс необходим для обеспечения уникальности сквозной нумерации задач по организации"
                        banner />
                </template>
            </a-form-model>
        </div>
        <div class="drawer_footer">
            <a-button
                :loading="loading"
                @click="formSubmit()"
                :block="isMobile ? true : false"
                type="primary">
                Сохранить
            </a-button>
        </div>
        <a-modal
            class="cropper_modal"
            :closable="false"
            :visible="cropModal"
            :zIndex="99999">
            <div v-if="dataUrl" class="relative">
                <div class="action_btn absolute flex items-center">
                    <a-button icon="undo" @click="cropper.rotate(-45)" />
                    <a-button class="ml-1" @click="cropper.rotate(45)" icon="redo" />
                </div>
                <img
                    ref="avatarImg"
                    @load.stop="createCropper"
                    :src="dataUrl" />
            </div>
            <template slot="footer">
                <a-button @click="closeCropModal()">
                    {{$t('close')}}
                </a-button>
                <a-button type="primary" @click="uploadImage()" :loading="uploadLoading">
                    Загрузить
                </a-button>
            </template>
        </a-modal>
    </a-drawer>
</template>

<script>
import {checkImageWidthHeight, hashString, getFileExtension} from '@/utils/utils'
import eventBus from '@/utils/eventBus'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import DrawerSelectUser from './Drawers/DrawerSelectUser'
import DrawerSelectOrganization from './Drawers/DrawerSelectOrganization'

import { mapActions, mapState } from 'vuex'

export default {
    name: "OrganizationCreateDrawer",
    components: {
        DrawerSelectUser,
        DrawerSelectOrganization
    },
    props: {
        zIndex: {
            type: Number,
            default: 1010
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        formShortNameLabel() {
            return 'Сокращенное наименование'
        },
        
        formFullNameLabel() {
            const prop = 'Полное наименование '
            if(this.isDepartment)
                return prop + 'отдела'
            if(this.isStructure)
                return prop + 'подразделения'
            return prop + 'организации'
        },
        drawerTitle() {
            const action = this.edit ? 'Редактировать ' : 'Добавить '
            if(this.isDepartment)
                return action + 'отдел'
            if(this.isStructure)
                return action + 'подразделение'
            return action + 'организацию'
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        drawerWidth() {
            if(this.windowWidth > 500)
                return 500
            else {
                return '100%'
            }
        },
        isStructure() {
            return this.organizationType === 'subdivision'
        },
        rules() {
            const rules = {
                name: [
                    {
                        required: true,
                        message: 'Обязательно для заполнения',
                        trigger: 'blur'
                    }
                ],
            }
            if(this.isDepartment || this.isStructure) {
                rules.director = [
                    {
                        required: true,
                        message: 'Обязательно для заполнения',
                        trigger: 'blur'
                    }
                ]
            } 
            if(!this.isDepartment) {
                rules.doc_prefix = [
                    {
                        max: 4,
                        message: 'Максимум 4 символа',
                        trigger: 'blur'
                    },
                    {
                        required: false,
                        message: 'Обязательно для заполнения',
                        trigger: 'blur'
                    },

                ]
            }
            return rules
        }
    },
    created() {
        eventBus.$on('create_organization', ({
            organizationType,
            organizationParent=null,
            organization=null,
            isDepartment=false
        }) => {
            this.organizationType = organizationType
            this.organizationParent = organizationParent
            this.organization = organization
            this.isDepartment = isDepartment
            if(isDepartment) {
                this.form.director = ''
            }
            if((organization?.members_count === 1) && organization.director) {
                this.form.director = organization.director
            }
            this.visible = true

        })
        eventBus.$on('edit_organization', async ({
            organization,
            organizationParent=null,
            organizationType=null,
            isDepartment=false
        }) => {

            this.edit = true
            this.organization = organization
            this.organizationType = organizationType
            // TODO: рефакторинг
            this.organizationParent = organizationParent
            this.form = JSON.parse(JSON.stringify(organization))
            delete this.form.members_count
            if(organizationParent) {
                const { data } = await this.$http(`users/my_organizations/${organizationParent}/detail`)
                this.form.contractor_parent = data
            }
            this.isDepartment = isDepartment
            if(this.organizationParent && !isDepartment) {
                this.organizationType = 'subdivision'
            }
            this.logoUrl = organization.logo

            this.visible = true
        })
    },
    data() {
        return {
            isDepartment: false,
            organization: null,
            organizationType: null,
            organizationParent: null,
            userDrawer: false,
            edit: false,
            visible: false,
            minSize: 150,
            file: null,
            cropModal: false,
            uploadLoading: false,
            logoUrl: null,
            dataUrl: null,
            form: {
                name: '',
                full_name: '',
                inn: '',
                email: '',
                phone: '',
                logo: null,
                director: '',
                doc_prefix: ''
            },
            cropperOptions: {
                aspectRatio: 1 / 1
            },
            loading: false
        }
    },
    methods: {
        ...mapActions({
            addDepartment: 'organization/addDepartment',
            addOrganization: 'organization/addOrganization',
            addStructure: 'organization/addStructure',
            updateDepartment: 'organization/updateDepartment',
            getOrganizationActionInfo: 'organization/getOrganizationActionInfo',
        }),
        afterVisibleChange(vis) {
            if(!vis) {
                this.form = {
                    name: '',
                    full_name: '',
                    inn: '',
                    email: '',
                    phone: '',
                    logo: null,
                    director: '',
                    doc_prefix: ''
                }
                this.organizationType = null
                this.organizationParent = null
                this.isDepartment = false
                this.edit = false
                this.logoUrl = null
                this.closeCropModal()
            }
        },
        closeCropModal() {
            this.cropModal = false
            this.dataUrl = null
            this.file = null
        },
        uploadImage() {
            this.cropper.getCroppedCanvas().toBlob(async (avatar) => {
                try {
                    const exc = getFileExtension(this.file.name),
                        filename = `${hashString(this.file.name)}.${exc}`

                    this.uploadLoading = true
                    let formData = new FormData()
                    formData.append("upload", avatar, filename)

                    const { data } = await this.$http.post('/common/upload/', formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    if(data?.length) {
                        if(this.edit) {
                            const aData = await this.$http.post(`/users/my_organizations/${this.form.id}/logo/`, {
                                logo: data[0].id
                            })
                            if(aData?.data?.id) {
                                eventBus.$emit('updateTableOrg', aData.data)
                                this.logoUrl = aData.data.logo
                            }
                        } else {
                            this.logoUrl = data[0].path
                            this.form.logo = data[0].id
                        }

                        this.closeCropModal()
                    }
                } catch(e) {
                    console.log(e)
                    this.$message.error(this.$t('error'))
                } finally {
                    this.uploadLoading = false
                }
            })
        },
        createCropper() {
            this.cropper = new Cropper(this.$refs.avatarImg, this.cropperOptions)
        },
        async handleFileChange(event) {
            const file = Object.values(event.target.files)[0]
            if(file) {
                const fileSize = await checkImageWidthHeight(file)
                if(fileSize.width > this.minSize && fileSize.height > this.minSize) {
                    if(file.size / 1024 / 1024 < 10) {
                        let reader = new FileReader()
                        reader.onload = e => {
                            this.dataUrl = e.target.result
                        }
                        reader.readAsDataURL(file)
                        this.file = file
                        this.cropModal = true
                    } else
                        this.$message.error(this.$t('max_file_size', {size: 10}))
                } else
                    this.$message.error(this.$t('max_file_h_w', {size: this.minSize}))
            }
        },
        formSubmit() {
            this.$refs.orgForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = { ...this.form }
                        if(formData.director) {
                            formData.director = formData.director.id
                        }
                        let data
                        if(this.edit) {
                            data = await this.editOrganization(formData)
                        } else {
                            data = await this.createOrganizaton(formData)
                        }
                        console.log(data)
                        if(data) {
                            await this.getOrganizationActionInfo({ organizationId: data.id })
                        }
                    } catch(e) {
                        console.log(e)
                        this.$message.error('Ошибка')
                    } finally {
                        this.loading = false
                    }
                } else
                    return false
            })
        },
        async editOrganization(formData) {
            delete formData.logo

            const payload = formData
            if(this.isDepartment) {
                const data = await this.updateDepartment({
                    payload: payload,
                    departmentId: this.organization.id,
                    parentId: this.organizationParent
                })
                if(data) {
                    this.visible = false
                    this.$message.info('Отдел обновлен')
                }

            } else {
                if(formData.contractor_parent) {
                    formData.contractor_parent = {
                        contractor_parent: formData.contractor_parent.id,
                        relation_type: 'structural_division'
                    }
                }
                const { data } = await this.$http.put(`/users/my_organizations/${formData.id}/update/`, formData)
                if(data) {
                    if(formData.contractor_parent) {
                        //
                        // this.$store.commit('organization/MOVE_ORGANIZATION', {
                        //     data: data,
                        //     parentId: this.organizationParent,
                        //     organizationId: this.organization.id,
                        //     fosterParentId: formData.contractor_parent.contractor_parent
                        // })
                    }
                    this.visible = false
                    eventBus.$emit('updateTableOrg', {
                        ...data,
                        showChildren: formData.showChildren
                    })
                    if(!this.isDepartment)
                        this.$store.commit('organization/UPDATE_ORGANIZATION', {
                            updatedOrganization: data,
                            organizationParentId: this.organizationParent
                        })

                    this.$message.info('Организация обновлена')
                }
            }

        },
        async createOrganizaton(formData) {
            let data
            if(this.organizationType === 'subdivision') {
                const payload = {
                    ...formData,
                    director: formData.director,
                    contractor_parent: {
                        contractor_parent: this.organizationParent,
                        relation_type: 'structural_division'
                    }
                }
                data = this.addStructure({
                    payload: payload,
                    parentId: this.organizationParent,
                })

            } else if(this.isDepartment) {
                const payload = {
                    name: formData.name,
                    director: formData.director,
                    contractor_parent: {
                        contractor_parent: this.organizationParent,
                        relation_type: 'department'
                    }
                }
                data = this.addDepartment({
                    payload: payload,
                    key: this.organizationParent,
                })
            } else {
                const parentId = formData?.contractor_parent?.id || null
                const payload = {
                    ...formData,
                }
                if(parentId) {
                    payload.contractor_parent = {
                        contractor_parent: parentId,
                        relation_type: 'structural_division'
                    }
                }
                data = this.addOrganization({
                    payload,
                    parentId: parentId,
                    relationType: 'structural_division'
                })
            }
            if(data) {
                this.visible = false
                eventBus.$emit('orgTableReload')
                this.$message.info('Организация создана')
            }
            return data
        }
    },
    beforeDestroy() {
        eventBus.$off('create_organization')
        eventBus.$off('edit_organization')
    }
}
</script>

<style lang="scss" scoped>
.custom_border {
    border: 1px solid var(--borderColor);

}
.oc_drawer{
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
            padding: 0px;
        }
        .ant-drawer-header{
            padding-left: 20px;
            padding-right: 20px;
        }
        .ant-drawer-body{
            height: calc(100% - 40px);
            padding: 0px;
        }
        .drawer_body{
            height: calc(100% - 40px);
            overflow-y: auto;
            overflow-x: hidden;
            padding: 20px;
        }
        .drawer_footer{
            display: flex;
            align-items: center;
            height: 40px;
            border-top: 1px solid #e8e8e8;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}
</style>