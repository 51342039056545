<template>
    <div>
        <CreateOrganization />
        <InviteDrawer />
        <InviteModal />
        <OrganizationInvite />
        <!-- <OrganizationEnter /> -->
        <OrganizationMap />
        <InviteOrganizationModal />
        <OrgInfoDrawer/>
        <UniversalDrawer />
    </div>
</template>

<script>
import CreateOrganization from './components/CreateOrganization.vue'
import InviteDrawer from './components/InviteDrawer'
import InviteModal from './components/InviteModal'
import OrganizationInvite from './components/OrganizationInvite'
import InviteOrganizationModal from './components/InviteOrganizationModal'
import OrganizationMap from './components/OrganizationMap'
import OrgInfoDrawer from './components/OrgInfo/OrgInfoDrawer.vue'
import UniversalDrawer from '@apps/Team/components/Drawers/UniversalDrawer'
import store from "./store/index"
import eventBus from '@/utils/eventBus'


export default {
    components: {
        UniversalDrawer,
        CreateOrganization,
        InviteDrawer,
        InviteModal,
        OrganizationInvite,
        OrganizationMap,
        InviteOrganizationModal,
        OrgInfoDrawer,
    },
    created() {
        if(!this.$store.hasModule('organization')) {
            this.$store.registerModule("organization", store)
        }
    },
    mounted() {
        eventBus.$on('add_organization_task', ({ task }) => {
            this.$store.commit('organization/ADD_ORGANIZATION_TASK', { 
                task: task, 
                organization: task.organization 
            })
        })
    },
    beforeDestroy() {
        eventBus.$off('add_organization_task')
    }
}
</script>