<template>
    <a-drawer
        :title="null"
        :width="drawerWidth"
        class="statistics_drawer"
        :class="showNavigation && 'navigation_active'"
        destroyOnClose
        @close="closeDrawer"
        :zIndex="1000"
        :visible="drawerVisible">
        <div class="drawer_header">
            <template v-if="isHeaderReady">
                <div class="flex items-center truncate">
                    <div 
                        :key="organizationLogo" 
                        class="mr-2">
                        <a-avatar 
                            :size="30"
                            :src="organizationLogo"
                            icon="fi-rr-users-alt" 
                            flaticon />
                    </div>
                    <span class="font-semibold truncate">{{ drawerTitle }}</span>
                </div>
            </template>
            <template v-else>
                <div class="flex items-center flex-grow">
                    <div class="mr-2">
                        <a-avatar 
                            class="custom_avatart_skeleton"
                            :size="30" />
                    </div>
                    <div class="custom_header_skeleton"></div>
                </div>
            </template>
            <a-button 
                type="ui"
                shape="circle"
                ghost
                icon="fi-rr-cross"
                flaticon
                @click="closeDrawer" />
        </div>
        <template v-if="showNavigation">
            <div class="drawer_navigation">
                <template>
                    <a-tabs 
                        default-active-key="" 
                        v-model="activeTab"
                        @change="changeActiveTab">
                        <a-tab-pane 
                            key="employees" 
                            tab="Сотрудники" />
                        <a-tab-pane 
                            key="permissions" 
                            tab="Роли" />
                    </a-tabs>
                </template>
            </div>
        </template>
        <div class="drawer_body">
            <template v-if="isBodyReady">
                <component 
                    :is="widget"
                    :organization="organization"
                    :org="organization" 
                    :isAdmin="isAdmin"
                    :parentId="parentId"
                    :isDepartment="isDepartment"/>
            </template>
            <template v-else>
                <div class="w-full flex justify-center pt-8">
                    <a-spin />
                </div>
            </template>
        </div>
        <div class="drawer_footer">
            <template v-if="activeTab === 'permissions' && isMobile">
                <a-button 
                    type="primary" 
                    icon="plus"
                    block
                    class="mr-2"
                    size="large"
                    @click="openCreateRoleDrawer">
                    Добавить роль
                </a-button>
                <CreateRoleDrawer 
                    ref="createRoleDrawer"
                    :organization="organization"/>
            </template>

        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import CreateRoleDrawer from '../Permissions/CreateRoleDrawer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        CreateRoleDrawer
    },
    data() {
        return {
            drawerVisible: false,
            component: null,
            organization: null,
            organizationId: null,
            parentId: null,
            isDepartment: false,
            organizationLoading: false,
            actions: null,
            isAdmin: false,
            activeTab: 'employees'
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
            isMobile: state => state.isMobile,
        }),
        drawerTitle() {
            return this.organization?.name || 'Организация'
        },
        drawerWidth() {
            return this.windowWidth > 1024 ? 1024 : this.windowWidth
        },
        organizationLogo() {
            return this.organization?.logo
        },
        isDetailPage() {
            return this.component === 'detail'
        },
        showNavigation() {
            return this.isDetailPage && this.actions?.edit
        },
        widget() {
            if(this.component === 'statistics')
                return () => import('@apps/Team/components/Statistics/TheStatistics')
            if(this.isDetailPage) {
                if(this.activeTab === 'employees')
                    return () => import('@apps/Team/components/OrgInfo')
                if(this.activeTab === 'permissions')
                    return () => import('@apps/Team/components/Permissions')
            }
            return null
        },
        isBodyReady() {
            return this.widget && this.organization && !this.organizationLoading
        },
        isHeaderReady() {
            return this.organization && !this.organizationLoading
        }
    },
    watch: {
        async '$route.query'(query) {
            const components = [
                'statistics',
                'detail'
            ]
            if(components.includes(query.organization_drawer) && query.organization_id) {
                this.activeTab = query.tab || 'employees'
                this.parentId = query.parent_id || null
                this.isDepartment = query.is_department === 'true'
                this.component = query.organization_drawer
                this.organizationLoading = true
                this.openDrawer()
                this.getOrganizationDetail(query.organization_id)
                this.getActions(query.organization_id)
            }
        }
    },
    methods: {
        async openCreateRoleDrawer() {
            this.$refs.createRoleDrawer.openDrawer()
        },
        async changeActiveTab(tab) {
            const query = JSON.parse(JSON.stringify(this.$route.query))
            query.tab = tab
            await this.$router.replace({ query })
        },  
        getOrganizationDetail(organizatonId) {
            const url = this.isDepartment ? `users/my_organizations/departments/${organizatonId}/detail` 
                : `users/my_organizations/${organizatonId}/detail`
            this.$http(url)
                .then(({ data }) => {
                    this.organization = data
                })
                .catch(error => {
                    console.error('Не удалось получить организацию', error)
                })
                .finally(() => {
                    this.organizationLoading = false
                })
        },
        async getActions(organizationId) {
            try {
                this.actionLoading = true
                const organization = this.isDepartment ? this.parentId : organizationId
                const url = `/users/my_organizations/${organization}/action_info/`
                const { data } = await this.$http.get(url)
                if(data?.actions) {
                    this.actions = data.actions

                } 
            } catch(error) {
                console.error(error)
            } finally {
                this.actionLoading = false
            }
        },
        closeDrawer() {
            this.drawerVisible = false
            this.clearQuery()
        },
        openDrawer() {
            this.drawerVisible = true
        },
        async clearQuery() {
            const query = JSON.parse(JSON.stringify(this.$route.query))
            delete query.organization_id
            delete query.parent_id
            delete query.is_department
            delete query.organization_drawer
            await this.$router.replace({ query })
        },
    }
}
</script>

<style lang="scss" scoped>
$footer-height: 48px;
$header-height: 40px;
$navigation-height: 44px;

.custom_header_skeleton {
    height: 1rem;
    background-color: #f2f2f2;
    width: 38%;
}
.custom_avatart_skeleton {
    flex-shrink: 0;
}
.statistics_drawer {
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content {
            overflow: hidden;
            padding: 0px;
        }
        .ant-drawer-header-no-title {
            display: none;
        }
        .ant-drawer-body {
            height: 100%;
            padding: 0px;
        }
        .drawer_body {
            $body-height: calc(100% - $footer-height - $header-height);
            height: $body-height;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 15px;
        }
        .drawer_footer {
            display: flex;
            align-items: center;
            height: $footer-height;
            border-top: 1px solid #e8e8e8;
            padding-left: 15px;
            padding-right: 15px;
        }
        .drawer_header {
            height: 40px;
            border-bottom: 1px solid #e8e8e8;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .drawer_navigation {
            // height: 40px;
            // border-bottom: 1px solid #e8e8e8;
            // padding: 5px 15px;
            .ant-tabs-bar {
                margin: 0;
            }
        }
    }
}

.statistics_drawer.navigation_active {
    &::v-deep{
        .drawer_body {
            $body-height: calc(100% - $footer-height - $header-height - $navigation-height);
            height: $body-height;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 15px;
        }
    }
}
</style>

